import * as React from "react";
// import video1 from "./assets/technology.mp4";
import tigr from "./assets/TIGR_Waveguide_04052025.gif";

import "./assets/styles.css";
import NavBar from "./navbar";

// styles
const imgStyle = {
  color: "#232129",
  padding: 10,
  width: "100%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const footerStyle = {
  textAlign: "center",
  color: "#716969",
  fontSize: 10,
};

const pageStyles = {
  color: "#232129",
  backgroundColor: "#ffffff",
  padding: 0,
  // height: "100%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Cogni-Trax</title>
      <NavBar />

      {/* <video width="100%" height="100%" controls autoPlay>
        <source src={video1} type="video/mp4" />
      </video> */}
      <div className="content">
        <img src={tigr} style={imgStyle} alt="cogni-trax technology" />
      </div>

      <div id="footer" style={footerStyle}>
        <h3> ceo@cogni-trax.com </h3>
        <p className="copyright">&copy; Cogni-Trax </p>
      </div>
    </main>
  );
};

export default IndexPage;
